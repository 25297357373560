import React, { useState, useRef, useEffect } from 'react';
import { styled } from 'shamrock-clover-ui';
import { LinearProgress } from './LinearProgress';

// Import Table Types
import { Column, Row, SortOrder } from './TableTypes';
// Import Table Components
import StyledTableHeader from './TableHeader';
import StyledTableRow from './TableRow';
import { StyledPaginationFooter } from './PaginationFooter';
import { useReactToPrint } from 'react-to-print';
import { exportSpreadsheet } from './utils';
import xlsx from './XlsxInterface';

interface TableProps {
  columns: Column[];
  emptyMessage?: string;
  footerOptions?: {
    rowsPerPage?: number;
    page?: number;
    rowsPerPageSelector?: boolean;
    totalRowsDisplay?: 'pages' | 'rows';
    height?: number;
    customRowsPerPageOptions?: number[];
  };
  rows: Row[];
  sortingOptions?: {
    onSort?: (columnIndex?: number, direction?: SortOrder) => void;
    sortedColumnIndex?: number;
    sortDirection?: SortOrder;
  };
  loading?: boolean;
  totalRows?: number;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
}

export type TableExportOptions = {
  xlsx: xlsx;
  includeColumnHeaders?: boolean;
  dataOverride?: Row[];
  title?: string;
};

type TablePrintOptions = {
  printAllRows?: boolean;
};

export const StyledTableContainer = styled.table`
  border-collapse: collapse;
  width: 100%;
  > thead > div {
    position: absolute;
    margin-top: -3px;
  }
`;

export const StyledEmptyMessage = styled.div`
  text-align: center;
  font-size: 24pt;
  font-weight: 300;
  font-family: proxima-nova, sans-serif;
  color: ${(props) => props.theme.colors.gray[70]};
  border: 1px solid ${(props) => props.theme.colors.gray[30]};
  border-top: none;
  padding: 40px 0;
`;

const StyledFooterContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.gray[30]};
  border-top: none;
  background-color: ${(props) => props.theme.white};
`;

const SmrTable = React.forwardRef<any, TableProps>((props, ref) => {
  const {
    columns,
    footerOptions,
    rows,
    emptyMessage,
    sortingOptions,
    loading,
    totalRows,
    onChangePage,
    onChangeRowsPerPage,
  } = props;
  const [currentPage, setCurrentPage] = useState(footerOptions?.page ?? 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    footerOptions?.rowsPerPage ?? 10,
  );
  const contentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });
  React.useImperativeHandle(ref, () => ({
    exportSpreadsheet: (options?: TableExportOptions) => {
      exportSpreadsheet(rows, columns, options);
    },
    printTable: (options?: TablePrintOptions) => {
      if (options?.printAllRows) {
        const originalPage = currentPage;
        const currentRowsPerPage = rowsPerPage;
        setCurrentPage(1);
        setRowsPerPage(rows.length);
        setTimeout(() => {
          handlePrint();
          setRowsPerPage(currentRowsPerPage);
          setCurrentPage(originalPage);
        });
      } else {
        handlePrint();
      }
    },
    setRowsPerPage: (newRowsPerPage: number) => {
      setRowsPerPage(newRowsPerPage);
    },
    rowsPerPage,
    setCurrentPage: (newCurrentPage: number) => {
      setCurrentPage(newCurrentPage);
    },
    currentPage,
  }));

  useEffect(() => {
    // if onChangePage is defined, we don't want to set the current page to 1
    // when the rows change, because the parent component is handling the page state
    if (onChangePage) {
      return;
    }
    if (Math.ceil(rows.length / rowsPerPage) < currentPage) {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows.length]);

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setCurrentPage(1);
  }, [rowsPerPage]);

  const handleRowChange = (rowAmount: number) => {
    setRowsPerPage(rowAmount);
    if (onChangeRowsPerPage) {
      onChangeRowsPerPage(rowAmount);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (onChangePage) {
      onChangePage(page);
    }
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedRows = rows.slice(startIndex, endIndex);

  return (
    <div ref={contentRef} style={{ transform: 'scale(1)' }}>
      <StyledTableContainer>
        <thead>
          <StyledTableHeader
            columns={columns}
            sortColumn={sortingOptions?.onSort}
            sortedColumnIndex={sortingOptions?.sortedColumnIndex}
            sortDirection={sortingOptions?.sortDirection}
          />
          {loading && <LinearProgress />}
        </thead>
        <tbody>
          {rows.length > 0 &&
            // if onChangePage is defined, we want to display all rows returned from api
            (onChangePage ? rows : paginatedRows).map((row, index) => (
              <StyledTableRow
                key={index}
                columns={columns}
                cells={row.cells}
                onClick={row.onClick}
                sortedColumnIndex={sortingOptions?.sortedColumnIndex}
                sortDirection={sortingOptions?.sortDirection}
              />
            ))}
        </tbody>
      </StyledTableContainer>
      {rows.length === 0 && !loading && (
        <StyledEmptyMessage>{emptyMessage ?? 'No data.'}</StyledEmptyMessage>
      )}
      <StyledFooterContainer>
        <StyledPaginationFooter
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          customRowsPerPageOptions={footerOptions?.customRowsPerPageOptions}
          disabled={rows.length === 0}
          totalRows={totalRows ?? rows.length}
          footerOptions={footerOptions}
          onChangePage={(page: number) => handlePageChange(page)}
          onChangeRowsPerPage={(rowAmount: number) =>
            handleRowChange(rowAmount)
          }
          loading={loading}
        />
      </StyledFooterContainer>
    </div>
  );
});

SmrTable.displayName = 'SmrTable';
export default SmrTable;
