import { getUserEmail } from './authUtils';
import { FEATURE_SERVICE_URL } from './constants';

export interface FreightAdminFeatures {
  isRtsMilesServiceFallbackEnabled: boolean;
  isAuth0Enabled?: boolean;
  isGreenscreenEnabled: boolean;
  isDNBEnabled: boolean;
}

export const getFeature = async (feature: string) => {
  const isActive = await fetch(
    `${FEATURE_SERVICE_URL}${feature}/${getUserEmail()}`,
  )
    .then((res) => res.json())
    .then((data) => {
      return data.status === 'always-active';
    });
  return isActive;
};

export const getAllFeatures = async (): Promise<FreightAdminFeatures> => {
  const result = await fetch(
    `${FEATURE_SERVICE_URL}active?email=${getUserEmail()}`,
  );
  const json = await result.json();
  const isRtsMilesServiceFallbackEnabled = json.includes('RTD-3419');
  const isAuth0Enabled = json.includes(
    'c217e9885b816ab398741190a4a08267309fedf7b5060e213cd1ec6ff2dff035',
  );
  const isGreenscreenEnabled = json.includes(
    '8021d852821e5aa46873768be1af0eb407b08cc7215798eb6bc1d24af96d89bc',
  );
  const isDNBEnabled = json.includes(
    '582b55bcd2daf4d2bb3d960ff4737a8bd13772021d330694dc505b5e06999552',
  );
  return {
    isRtsMilesServiceFallbackEnabled,
    isAuth0Enabled,
    isGreenscreenEnabled,
    isDNBEnabled,
  };
};
