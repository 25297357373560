/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { SecondaryNavigation, CloverThemeProvider } from 'shamrock-clover-ui';
import { useNavigate, useLocation } from 'react-router-dom';
import * as FirebaseUtils from 'utils/firebaseUtils';

const getStartingIndexFromPath = (path: string): number => {
  switch (path) {
    case '/carriercapacity':
      return 0;
    case '/carriercapacity/carriers':
      return 1;
    default:
      return 0;
  }
};

const CapacitySubHeader: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Use memo to prevent extra re-rendering
  const startingIndex = React.useMemo(
    () => getStartingIndexFromPath(location.pathname.toLowerCase()),
    [location.pathname],
  );

  const handleSearchCapacityClicked = () => {
    try {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.CLICK,
        FirebaseUtils.FirebaseModules.CARRIER_CAPACITY,
        FirebaseUtils.FirebasePages.SEARCH_CAPACITY,
        {
          description: 'Search Capacity Page View',
        },
      );
    } catch (e) {
      console.log('Error logging firebase event');
    }
    navigate(`/carrierCapacity`);
  };

  const handleCarriersClicked = () => {
    try {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.CLICK,
        FirebaseUtils.FirebaseModules.CARRIER_CAPACITY,
        FirebaseUtils.FirebasePages.CARRIERS,
        {
          description: 'Carriers Page View',
        },
      );
    } catch (e) {
      console.log('Error logging firebase event');
    }
    navigate(`/carrierCapacity/carriers`);
  };

  const tabs = [
    {
      title: 'Search Capacity',
      onClick: handleSearchCapacityClicked,
    },
    {
      title: 'Carriers',
      onClick: handleCarriersClicked,
    },
  ];

  return (
    <CloverThemeProvider>
      <div
        className="secondaryNavigation"
        style={{
          position: 'relative',
          boxShadow: '0 2px 2px 0 rgb(0 0 0 / 22%)',
          overflowX: 'hidden',
        }}
      >
        <SecondaryNavigation
          tabs={tabs}
          startingIndex={startingIndex}
          key={startingIndex}
        />
      </div>
    </CloverThemeProvider>
  );
};

export default CapacitySubHeader;
