import { FC } from 'react';
import { Audit, GetAuditResponse } from '../creditRequestTypes';
import { Column, Row } from 'components/cloverTable/TableTypes';
import { StyledTableText, StyledTableContainer } from '../creditRequestStyles';
import SmrTable from 'components/cloverTable/Table';
import { StyledCheckmark } from 'features/carrierCapacity/CarrierCapacityStyles';
import Icon, { ImageName } from '../../../components/Icon';
import { Flex } from 'components/Flex';
import dayjs from 'dayjs';

interface CheckHistoryTableProps {
  results: GetAuditResponse | undefined;
  isLoading: boolean;
  pageNumber: number;
  limit: number;
  setPageNumber: (pageNumber: number) => void;
  setLimit: (limit: number) => void;
}

const formatCheckHistoryAddress = (audit: Audit) => {
  if (
    audit.address.length > 21 ||
    audit.city.length + audit.stateCode.length > 19
  ) {
    return (
      <Flex padding="8px 0 8px 0">
        <StyledTableText fontSize="14px">
          {audit.address}, {audit.city}, {audit.stateCode} {audit.zipCode}
        </StyledTableText>
      </Flex>
    );
  } else {
    return (
      <Flex flexDirection="column" padding="8px 0 8px 0">
        <StyledTableText fontSize="14px">{audit.address}</StyledTableText>
        <StyledTableText fontSize="14px">
          {audit.city}, {audit.stateCode}
        </StyledTableText>
        <StyledTableText fontSize="14px">{audit.zipCode}</StyledTableText>
      </Flex>
    );
  }
};

const formatDunsNumber = (dunsNumber: string): string => {
  const part1 = dunsNumber.slice(0, 2);
  const part2 = dunsNumber.slice(2, 5);
  const part3 = dunsNumber.slice(5, 10);

  return `${part1}-${part2}-${part3}`;
};

const getRating = (status: string): string => {
  switch (status) {
    case 'Approved':
      return 'GreenCheckmark';
    case 'Tentative':
      return 'YellowSubtract';
    case 'Declined':
    default:
      return 'RedX';
  }
};

const getSearchedOnText = (searchedOn: Date): string => {
  const date = dayjs(searchedOn);
  const today = dayjs();
  const diffInDays = today.diff(date, 'day');

  if (diffInDays === 0) {
    return 'Today';
  } else if (diffInDays === 1) {
    return 'Yesterday';
  } else if (diffInDays < 90) {
    return `${diffInDays} days ago`;
  } else {
    return '90+ days ago';
  }
};

const CheckHistoryTable: FC<CheckHistoryTableProps> = ({
  results,
  isLoading,
  pageNumber,
  limit,
  setPageNumber,
  setLimit,
}) => {
  const { data, total } = results ?? {};

  const tableRows: Row[] =
    isLoading || !data
      ? []
      : data.map((audit: Audit) => {
          const startDate = audit.startDate
            ? dayjs(audit.startDate).format('YYYY')
            : '— ';
          const controlDate = audit.controlDate
            ? dayjs(audit.controlDate).format('YYYY')
            : ' —';

          return {
            cells: [
              {
                content: (
                  <Icon
                    name={getRating(audit.status) as ImageName}
                    alt={audit.status}
                  />
                ),
                value: audit.status,
              },
              {
                content: (
                  <StyledTableText fontSize="16px">
                    {audit.companyName}
                  </StyledTableText>
                ),
                value: audit.companyName,
              },
              {
                content: formatCheckHistoryAddress(audit),
                value: `${audit.address}, ${audit.city}, ${audit.stateCode} ${audit.zipCode}`,
              },
              {
                content: (
                  <>
                    {audit.headquarters ? (
                      <StyledCheckmark
                        icon="checkCircleGreen"
                        color="gray3"
                        paddingLeft="30px"
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ),
                value: audit.headquarters ? audit.headquarters.toString() : '—',
              },
              {
                content: (
                  <StyledTableText fontSize="14px">
                    {formatDunsNumber(audit.dunsNumber)}
                  </StyledTableText>
                ),
                value: audit.dunsNumber || '—',
              },
              {
                content: (
                  <StyledTableText fontSize="14px">
                    {audit.dbRating}
                  </StyledTableText>
                ),
                value: audit.dbRating ?? '—',
              },
              {
                content: (
                  <StyledTableText fontSize="14px">
                    {audit.paydex ? audit.paydex.toString() : '—'}
                  </StyledTableText>
                ),
                value: audit.paydex ? audit.paydex.toString() : '—',
              },
              {
                content: (
                  <StyledTableText fontSize="14px">
                    {startDate}/{controlDate}
                  </StyledTableText>
                ),
                value: `${startDate}/${controlDate}`,
              },
              {
                content: (
                  <StyledTableText fontSize="14px">
                    {audit.numberOfEmployees
                      ? audit.numberOfEmployees.toString()
                      : '—'}
                  </StyledTableText>
                ),
                value: audit.numberOfEmployees
                  ? audit.numberOfEmployees.toString()
                  : '—',
              },
              {
                content: (
                  <>
                    {audit.publiclyOwned ? (
                      <StyledCheckmark
                        icon="checkCircleGreen"
                        color="gray3"
                        paddingLeft="30px"
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ),
                value: audit.publiclyOwned
                  ? audit.publiclyOwned.toString()
                  : '—',
              },
              {
                content: (
                  <Flex flexDirection="column">
                    <StyledTableText fontSize="14px" fontWeight="bold">
                      {getSearchedOnText(audit.searchedOn)}
                    </StyledTableText>
                    <StyledTableText fontSize="14px">
                      {dayjs(audit.searchedOn).format('MM/DD/YYYY')}
                    </StyledTableText>
                    <StyledTableText fontSize="14px">
                      {audit.searchedBy}
                    </StyledTableText>
                  </Flex>
                ),
                value: audit.searchedOn
                  ? dayjs(audit.searchedOn).format('MM/DD/YYYY')
                  : '—',
              },
            ],
          };
        });

  const tableColumns: Column[] = [
    {
      key: 'status',
      label: 'Rating',
      width: 4,
      alignment: 'center',
    },
    {
      key: 'companyName',
      label: 'Company/Trade Name',
      width: 19,
    },
    {
      key: 'address',
      label: 'Location',
      width: 13,
    },
    {
      key: 'headquarters',
      label: 'HQ',
      width: 4.5,
      alignment: 'center',
    },
    {
      key: 'dunsNumber',
      label: 'DUNS#',
      width: 8,
    },
    {
      key: 'dbRating',
      label: 'D&B',
      width: 5,
    },
    {
      key: 'paydex',
      label: 'Paydex',
      width: 6,
    },
    {
      key: 'startDate',
      label: 'Start/Control Date',
      width: 11,
    },
    {
      key: 'numberOfEmployees',
      label: 'Employees',
      width: 8,
    },
    {
      key: 'publiclyOwned',
      label: 'Public',
      width: 6.5,
      alignment: 'left',
    },
    {
      key: 'searchedOn',
      label: 'History',
      width: 15,
      isSortable: true,
    },
  ];

  return (
    <StyledTableContainer marginTop="16px">
      <SmrTable
        loading={isLoading}
        columns={tableColumns}
        rows={tableRows}
        footerOptions={{
          page: pageNumber,
          rowsPerPage: limit,
          rowsPerPageSelector: true,
          totalRowsDisplay: 'rows',
          customRowsPerPageOptions: [25, 50, 75, 100],
        }}
        totalRows={total}
        //TODO: Finish implementing pagination
        onChangePage={setPageNumber}
        onChangeRowsPerPage={setLimit}
        // TODO: Wire up sorting functionality
      />
    </StyledTableContainer>
  );
};

export default CheckHistoryTable;
