import { useEffect, useState } from 'react';
import CheckHistoryToolbar from './components/CheckHistoryToolbar';
import { Container, StyledCreditCheckContainer } from './creditRequestStyles';
import dayjs, { Dayjs } from 'dayjs';
import dnbCreditApi from './state';
import { GetAuditRequestParams } from './creditRequestTypes';
import CheckHistoryTable from './components/CheckHistoryTable';

const getHistoryType = (type: string) => {
  switch (type) {
    case 'My history':
    default:
      return 'USER';
    case 'All credit history':
      return 'ALL';
  }
};

const CheckHistory = () => {
  const defaultStartDate = dayjs().subtract(90, 'day');
  const defaultEndDate = dayjs();

  const [selectedHistoryFilter, setSelectedHistoryFilter] = useState({
    optionName: 'My history',
  });
  const [companyValue, setCompanyValue] = useState('');
  const [startDate, setStartDate] = useState<Dayjs | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Dayjs | null>(defaultEndDate);
  const [offset, setOffset] = useState(1); // for pagination
  const [limit, setLimit] = useState(50); // for pagination
  // TODO: remove eslint comment when sorting is implemented
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orderBy, setOrderBy] = useState(-1); // for sorting, -1 for descending and 1 for ascending
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortBy, setSortBy] = useState('searchedOn'); // default sort by field
  const [getAudit, { data: getAuditData, isLoading }] =
    dnbCreditApi.endpoints.getAudit.useLazyQuery();

  const getAuditRequestParams: GetAuditRequestParams = {
    limit,
    offset,
    type: getHistoryType(selectedHistoryFilter.optionName),
    startDate: defaultStartDate.format('YYYY-MM-DD'),
    endDate: defaultEndDate.format('YYYY-MM-DD'),
    sortBy,
    orderBy,
  };

  // call the getAudit function on page load
  useEffect(() => {
    (async () => {
      try {
        await getAudit(getAuditRequestParams);
        // TODO: Total number of rows is persisting when moving between pages
        // need to update the total number of rows when the page changes
      } catch (error) {
        console.error('Error fetching audit data:', error);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewClick = async () => {
    let startDateForQuery = startDate;
    let endDateForQuery = endDate;
    // reset the offset to 1 when the user clicks View
    setOffset(1);
    // if the user clicks View without selecting a date, set the default date
    if (startDate === null) {
      setStartDate(defaultStartDate);
    }

    if (endDate === null) {
      setEndDate(defaultEndDate);
    }

    const updatedGetAuditRequestParams: GetAuditRequestParams = {
      ...getAuditRequestParams,
      offset: 1,
      startDate:
        startDateForQuery?.format('YYYY-MM-DD') ??
        defaultStartDate.format('YYYY-MM-DD'),
      endDate:
        endDateForQuery?.format('YYYY-MM-DD') ??
        defaultEndDate.format('YYYY-MM-DD'),
      ...(companyValue && { company: companyValue }),
    };

    getAudit(updatedGetAuditRequestParams);
  };

  const handleDownloadClick = () => {
    // TODO: Wire up download button
    console.log('Download button clicked');
  };

  const handleLimitChange = (newLimit: number) => {
    setLimit(newLimit);
    const updatedGetAuditRequestParams: GetAuditRequestParams = {
      ...getAuditRequestParams,
      offset: 1, // reset offset to 1 when limit changes
      limit: newLimit,
    };

    getAudit(updatedGetAuditRequestParams);
  };

  const handlePageChange = (page: number) => {
    setOffset(page);
    getAudit({ ...getAuditRequestParams, offset: page });
  };

  return (
    <Container>
      <StyledCreditCheckContainer>
        <CheckHistoryToolbar
          selectedHistoryFilter={selectedHistoryFilter}
          companyValue={companyValue}
          startDate={startDate}
          endDate={endDate}
          setSelectedHistoryFilter={setSelectedHistoryFilter}
          setCompanyValue={setCompanyValue}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleViewClick={handleViewClick}
          handleDownloadClick={handleDownloadClick}
        />
        <CheckHistoryTable
          results={getAuditData}
          isLoading={isLoading}
          pageNumber={offset}
          limit={limit}
          setPageNumber={handlePageChange}
          setLimit={handleLimitChange}
          // TODO: Pass Sorting functionality to CheckHistoryTable
        />
      </StyledCreditCheckContainer>
    </Container>
  );
};

export default CheckHistory;
