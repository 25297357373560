import { styled, Icon, Button } from 'shamrock-clover-ui';

export const Container = styled.div`
  height: calc(100vh - 100px);
  background-color: ${({ theme }) => theme.colors.gray[10]};
  padding: 16px 64px;
  font-size: 14px;
`;

export const StyledHeader = styled.p`
  font-size: 36px;
  margin-bottom: 0;
`;

export const Flex = styled.div`
  display: flex;
`;

export const PageNavFlex = styled(Flex)`
  align-items: center;
  padding: 7px;
  float: right;
`;

export const SpinnerContainer = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 50%;
  justify-content: center;
  position: absolute;
  width: 90%;
  z-index: 1;
`;

export const LabelText = styled.div`
  padding-left: 16px;
  margin-bottom: 4px;
  font-size: ${({ theme }) => theme.font.size.xxsmall};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  color: ${({ theme }) => theme.colors.gray[70]};
  text-transform: uppercase;
`;

export const StyledTable = styled.table`
  overflow: scroll;
  display: block;
  height: 50vh;
  border-collapse: collapse;
  width: 100%;
`;

export const TableContainer = styled.div`
  margin-top: 30px;
  padding: 10px;
  background-color: #ffffff;
`;

export const TableHead = styled.thead`
  display: table;
  width: 100%;
  table-layout: fixed;
  font-weight: bold;
`;

export const TableBody = styled.tbody`
  display: inline-table;
  table-layout: fixed;
  width: 100%;
`;

export const StyledRow = styled.tr`
  padding: 5px;
  border-bottom: 1px solid #0000001f;
`;

export const StyledCell = styled.td`
  padding: 13px;
`;

export const TableFooter = styled.div`
  background: #f9f9f9;
  width: 100%;
  height: 64px;
`;

export const CloverIcon = styled(Icon)`
  padding-left: 10px;
  padding-right: 10px;
`;

export const NoSearchText = styled.div`
  color: ${({ theme }) => theme.colors.gray[60]};
  font-size: 20px;
  text-align: center;
  padding-top: 30vh;
`;

export const Link = styled.a`
  color: #0091ea;
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledSecondaryNavigationContainer = styled.div`
  position: relative;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 22%);
  overflow-x: hidden;
`;

export const StyledCreditCheckContainer = styled.div`
  max-width: 1509px;
  min-width: 1180px;
`;

export const StyledCreditToolbarContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.gray[20]};
  height: 72px;
  align-items: center;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
`;

export const StyledInitialViewTextContainer = styled.div`
  display: flex;
  justify-content: start;
  height: 68px;
  line-height: 58px;
  width: auto;
  margin-top: 24px;
`;

export const StyledInitialViewText = styled.div`
  font-size: 48px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.gray[50]};
`;

export const StyledInputPillContainer = styled.div<{
  border?: boolean;
  width: string;
}>`
  width: ${({ width }) => width};
  height: 36px;
  background-color: white;
  border-radius: 20px;
  padding: 0px 15px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border: ${({ border }) => (border ? '1px solid red' : 'none')};
`;

export const StyledDropdownContainer = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  height: 36px;
  background-color: white;
  border-radius: 20px;
  padding: 0px 15px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  .SingleSelectMenuContainer {
    width: 185px;
    height: 96px;
    left: 78px;
    top: -5px;
    margin-top: 0px;
    overflow: hidden;
  }
  div {
    border-bottom: none;
    padding-top: 0px;
    color: black;
    div {
      padding-left: 0px;
      div:last-child {
        left: 105px;
      }
      div {
        font-size: 14px;
        text-wrap: nowrap;
        top: 10px;
        div {
          padding-top: 2px;
        }
      }
    }
  }
`;

export const StyledInput = styled.input`
  border: none;
  width: 100%;
  &:focus {
    outline: none;
  }
  ::placeholder {
    color: #333333;
  }
`;
export const StyledCreditComponentRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
`;

export const StyledCompanyNotFoundIcon = styled(Icon)`
  cursor: pointer;
`;

export const StyledCompanyNotFoundContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: #0091ea;
  font-size: 18px;

  &:hover {
    text-decoration: underline;
    color: #005c95;

    .companyNotFoundIcon {
      background-color: #005c95;
    }
  }
`;

export const StyledButton = styled(Button)<{ border: string }>`
  margin-top: 18px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  background-color: #eeeeee;
  border: ${({ border }) => border};
`;

export const StyledTextContainer = styled.div`
  justify-content: center;
  height: 70px;
  display: flex;
  align-items: center;
`;

export const StyledResultText = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  text-align: center;
`;

export const StyledTableContainer = styled.div<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => marginTop ?? '0px'};
  width: 100%;
  max-height: 565px;
  overflow-y: scroll;
  border-top: 1px solid #cccccc;
  thead {
    border-left: 2px solid #cccccc !important;
    border-right: 2px solid #cccccc !important;
    position: sticky;
    top: 0;
    z-index: 2;
    th {
      border-top: hidden;
      span {
        font-size: 14px;
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 16px;
        padding: 0px !important; // overwrite td:first-child's padding-left in clover
        height: 1px;
      }
    }
  }
  div:not(thead div) {
    position: sticky;
    bottom: 0;
  }
`;

export const StyledCheckmark = styled(Icon)<{ paddingLeft?: string }>`
  width: 16px;
  height: 16px;
`;

export const StyledTableCell = styled.div<{
  isHighlighted: boolean;
  isFirstTableCellInRow?: boolean;
}>`
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? '#EAF7FF' : 'inherited'};
  width: 100%;
  height: 100%;
  padding-top: 12px;
  padding-right: 16px;
  padding-bottom: 12px;
  padding-left: ${({ isFirstTableCellInRow }) => (isFirstTableCellInRow ? '24px' : '16px')}
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const StyledSearchButton = styled(Button)`
  margin-top: 18px;
`;

export const StyledTableText = styled.div<{
  fontSize: string;
  fontWeight?: string;
}>`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight ?? '400'};
  padding-left: 15px;
`;
