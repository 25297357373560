import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FreightAdminFeatures, getAllFeatures } from './featureUtils';

const defaultFlags: FreightAdminFeatures = {
  isRtsMilesServiceFallbackEnabled: false,
  isAuth0Enabled: undefined,
  isGreenscreenEnabled: false,
  isDNBEnabled: false,
};

export const FeatureFlagContext =
  createContext<FreightAdminFeatures>(defaultFlags);

interface FeatureFlagProviderProps {
  children: ReactNode;
}

export const FeatureFlagProvider: FC<
  React.PropsWithChildren<FeatureFlagProviderProps>
> = ({ children }) => {
  const [flags, setFlags] = useState<FreightAdminFeatures>(defaultFlags);

  useEffect(() => {
    // Simulated API call to fetch feature flags
    const fetchFeatureFlags = async () => {
      const {
        isRtsMilesServiceFallbackEnabled,
        isAuth0Enabled,
        isGreenscreenEnabled,
        isDNBEnabled,
      } = await getAllFeatures();
      setFlags({
        isRtsMilesServiceFallbackEnabled,
        isAuth0Enabled,
        isGreenscreenEnabled,
        isDNBEnabled,
      });
    };

    fetchFeatureFlags();
  }, []);

  return (
    <FeatureFlagContext.Provider value={flags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = (): FreightAdminFeatures => {
  return useContext(FeatureFlagContext);
};
